export const devEnvironment = {
	production: false,
	appId: 4,
	apiKey: "gHgHKRbIjdguCM4cv5481hdiF5hZGWZ4x12Ur-7v"
}

export const stagingEnvironment = {
	production: false,
	appId: 4,
	apiKey: "gHgHKRbIjdguCM4cv5481hdiF5hZGWZ4x12Ur-7v"
}

export const prodEnvironment = {
	production: true,
	appId: 4,
	apiKey: "gHgHKRbIjdguCM4cv5481hdiF5hZGWZ4x12Ur-7v"
}
